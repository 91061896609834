import React from "react";

const NotFound = () => {
    return (
        <div>
            <h1 className="absolute left-1/2 transform -translate-x-1/2 text-2xl mt-10">404. Not found</h1>
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                {/* eslint-disable-next-line no-undef */}
                <img src={process.env.PUBLIC_URL + "/img/404.png"} alt="A creeper from the aether"/>
            </div>
        </div>
    );
};

export default NotFound;
