import React from "react";
import Navbar from "../components/Navbar";

const Play = () => {
    return (
        <div>
            <Navbar/>
            <h1>PLAY</h1>
        </div>
    );
};

export default Play;
