import React from "react";

import { BrowserRouter as Router, Route } from "react-router-dom";

import Home from "./pages/Home";
import Play from "./pages/Play";
import About from "./pages/About";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";

function App() {
    return (
        <Router>
            <Route path="/" exact component={Home}/>
            <Route path="/play" exact component={Play}/>
            <Route path="/about" exact component={About}/>
            <Route path="/contact" exact component={Contact}/>
            <Route component={NotFound}/>
        </Router>
    );
}

export default App;
