import React from "react";

import { Link } from "react-router-dom";

const Navbar = () => {
    return (
        <div>
            <header>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/play">Play</Link></li>
                    <li><Link to="/about">About us</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                </ul>
            </header>
        </div>
    );
};

export default Navbar;
